html, body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    background: #1676A9;
    color: #FF9326;
    display: table;
    font-weight: 100;
    font-family: 'Lato';
}

.container {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}

.content {
    text-align: center;
    display: inline-block;
}

.title {
    font-size: 72px;
    font-weight: bold;
    margin-bottom: 40px;
}
